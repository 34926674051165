import React from 'react';

export interface IModalProps {
    visible: boolean;
}

export const Modal: React.FC<IModalProps> = (props) => {
    if (!props.visible) return null;
    return (<>
        <aside className="Modal__Overlay"></aside>
        <div className="Modal__Content">{props.children}</div>
    </>);
}