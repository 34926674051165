import * as React from "react"
import { Dialog } from '@headlessui/react';
import '../components/layout.scss';
import HubspotForm from 'react-hubspot-form';
import { useCookies } from 'react-cookie';
import Seo from "../components/seo";
import { Modal } from "../components/Modal";

const IndexPage: React.FC = () => {
  const [isNiceOpen, setIsNiceOpen] = React.useState(false);
  const [isNaughtyOpen, setIsNaughtyOpen] = React.useState(false);
  const [cookies, setCookie] = useCookies();

  const buttonOne = React.useRef<HTMLButtonElement>(null);
  const buttonTwo = React.useRef<HTMLButtonElement>(null);
  const buttonThree = React.useRef<HTMLButtonElement>(null);
  const buttonFour = React.useRef<HTMLButtonElement>(null);

  const isOdd = (num: number) => num % 2;


  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const randomPick = Math.floor(Math.random() * 11);
    let numWeeks = 1;
        let now = new Date();
        now.setDate(now.getDate() + numWeeks * 7);
    setCookie('result', randomPick, {
      expires: now
    });
    isOdd(randomPick) ? setIsNiceOpen(true) : setIsNaughtyOpen(true);
  }

  React.useEffect(() => {
    if (cookies.result) {
      isOdd(cookies.result) ? setIsNiceOpen(true) : setIsNaughtyOpen(true);
    }
  }, []);

  return (
    <>
      <Seo title="Naughty or Nice" description="" lang="en" />
    <div className="container">
      <img className="header" src="/header.png" alt="Have you been naughty or nice?" />
      <img className="sftct" src="/softcat.png" alt="Softcat elite partner" />
      <div className="fireplaceContainer">
        <img src="/fireplace.png" alt="Fireplace illustration" />
        <button onClick={(e) => handleClick(e)}>
          <img src="/stocking-1.png" alt="Stocking illustration" />
        </button>
        <button onClick={(e) => handleClick(e)}>
          <img src="/stocking-2.png" alt="Stocking illustration" />
        </button>
        <button onClick={(e) => handleClick(e)}>
          <img src="/stocking-3.png" alt="Stocking illustration" />
        </button>
        <button onClick={(e) => handleClick(e)}>
          <img src="/stocking-4.png" alt="Stocking illustration" />
        </button>
      </div>
      <img className="tree" src="/tree.png" alt="Tree illustration" />
      <img className="chair" src="/chair.png" alt="Chair illustration" />
      <Modal visible={isNiceOpen}>
            <img src="/winner-message.png" alt="You've been nice!" />
          <HubspotForm portalId={'4465129'} formId={'559ad2c6-bd1c-4fbc-85f4-763199cdd471'} />
      </Modal>
      <Modal visible={isNaughtyOpen}>

          <img src="/loser-message.png" alt="You've been nice!" />
          <HubspotForm portalId={'4465129'} formId={'46ac750e-5399-444f-82c8-16de29d765e4'} />


      </Modal>
    </div>
    </>
  )
}

export default IndexPage
